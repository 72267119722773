import Vue from 'vue'
import KwixeoSubscriptions from '@kwixeo/subscriptions-vue/src/install'
import axios from '@/axios'
import router from '@/router'
import store from '@/store'
import i18n from '@/lang/i18n'

Vue.use(KwixeoSubscriptions, {
  axios,
  router,
  store,
  i18n
})
