import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { termText, uppercaseFirst } from '@kwixeo/constructions-vue'

const menu: (i18n: VueI18n) => KwixeoInterface.MainMenuEntries = i18n => {
  if (Vue.prototype.$kwSubscriptions.store.getters['user/can']('constructionInterface', 1)) {
    return [
      {
        label: 'Accueil',
        path: '/constructions/dashboard',
        userPermission: ['constructionInterface', 1],
        appModule: 'construction'
      },
      {
        label: i18n.t('constructions.myPlanning'),
        path: '/constructions/my-planning',
        userPermission: ['constructionInterface', 1],
        appModule: 'construction'
      },
      {
        label: uppercaseFirst(termText('construction.myMenu', 'planning', true)) as string,
        path: '/constructions/my-planifications',
        userPermission: ['constructionInterface', 1],
        appModule: 'construction'
      },
    ]
  }

  if (!Vue.prototype.$kwSubscriptions.store.getters['user/team'].disable_app) {
    return []
  }

  if (!Vue.prototype.$kwSubscriptions.store.getters['user/team'].is_reseller) {
    return [
      {
        label: 'Entreprises',
        onlyAccountant: true,
        path: '/accountant/team'
      },
      {
        label: 'Invitations',
        onlyAccountant: true,
        path: '/accountant/invitations'
      }
    ]
  }

  return [
    {
      label: 'Experts-comptable',
      active: '/accountant',
      onlyAccountant: true,
      open: false,
      items: [
        {
          label: 'Entreprises',
          onlyAccountant: true,
          path: '/accountant/team'
        },
        {
          label: 'Invitations',
          onlyAccountant: true,
          path: '/accountant/invitations'
        }
      ]
    },
    {
      label: 'Marque blanche',
      active: '/admin',
      rootOrAdmin: true,
      open: false,
      items: [
        {
          label: 'Mon application',
          path: '/admin/my-app',
          rootOrAdmin: true
        },
        {
          label: 'Comptes',
          path: '/admin/team',
          rootOrAdmin: true
        },
        {
          label: 'Commandes',
          path: '/admin/command-list',
          rootOrAdmin: true
        },
        {
          label: 'Apps',
          path: '/admin/app',
          onlyRoot: true
        }
      ]
    }
  ]
}

export default menu
