import { Component, Mixins, Watch } from 'vue-property-decorator'
import { BModal, BButton, BFormRow, BCol, BRow } from 'bootstrap-vue'
import { TeamFormMixin } from '@kwixeo/interface-vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import axios from '@/axios'
import TeamInformations from '../TeamInformations/TeamInformations.vue'
import Modules from '../Modules/Modules.vue'
import ModulesClass from '../Modules/Modules'
import Invite from '../Invite/Invite.vue'
import InviteClass from '../Invite/Invite'
import Final from '../Final/Final.vue'
import Themes from '../Themes/Themes.vue'

@Component({
  components: {
    TeamInformations,
    Modules,
    Invite,
    Final,
    Themes,
    BModal, BButton, BFormRow, BCol, BRow,
    FontAwesomeIcon
  }
})
export default class WelcomeApp extends Mixins(TeamFormMixin) {
  private modalState = false

  private totalStep = 4
  private activeStep = 1

  @Watch('team.configs.welcome_modal', { immediate: true, deep: true })
  private watchWelcomeModel (): void {
    const name = this.$route.name
    if ((name === 'home' || name === null) && this.team.configs?.welcome_modal) {
      this.modalState = true
    }
  }

  @Watch('activeStep')
  private watchActiveStep (): void {
    axios.post('/tracking', {
      type: 'modal',
      name: 'welcome',
      path: this.activeStep,
    })
  }

  private hideModal (): void {
    this.modalState = false
    this.disableWelcome()
  }

  private previous (): void {
    this.activeStep--
  }

  private nextStep (): void {
    const component = this.$refs['step' + this.activeStep] as InviteClass|ModulesClass
    if (component && 'submit' in component && typeof component.submit === 'function') {
      component.submit()
    } else {
      this.next()
    }
  }

  private next (): void {
    if (this.activeStep === this.totalStep) {
      this.disableWelcome()
      return
    }
    this.activeStep++
  }

  private async disableWelcome (): Promise<void> {
    this.modalState = false

    axios.put('/team', {
        configs: {
          welcome_modal: 0
        }
      })
      .then(response => {
        this.formSubmitted(response)
      })
  }
}
