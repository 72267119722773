import Vue from 'vue'
import KwixeoCustomFields from '@kwixeo/custom-fields-vue/src/install'
import axios from '@/axios'
import store from '@/store'
import router from '@/router'
import i18n from '@/lang/i18n'

Vue.use(KwixeoCustomFields, {
  axios,
  store,
  router,
  i18n,
  fields: [
    {
      type: 'customer',
      formSelectLabel: 'Tiers (clients, prospects, ...)',
      label: 'Tiers',
      view: (customer: KwixeoInterface.GenericObject|null) => customer?.name,
      viewLink: (customer: KwixeoInterface.GenericObject|null) => {
        if (!customer) {
          return null
        }

        let type: string
        if (!customer.reference.customer && customer.reference.prospect) type = 'prospect'
        else if (customer.reference.provider) type = 'provider'
        else if (customer.reference.subcontractor) type = 'subcontractor'
        else type = 'customer'

        return {
          name: 'contacts-customer-item',
          params: {
            type,
            id: String(customer.id)
          }
        }
      },
      formParams: {
        label: 'name',
        reduce: (opt: KwixeoInterface.GenericObject) => opt.id
      },
      filterParams: {
        optionLabel: 'name',
        value: 'id'
      },
      params: {
        ajax: '/customer',
        lazyLoading: true
      }
    },
    {
      type: 'contact',
      formSelectLabel: 'Contacts',
      label: 'Contact',
      view: (contact: KwixeoInterface.GenericObject|null) => contact?.fullname,
      viewLink: (contact: KwixeoInterface.GenericObject|null) => {
        if (!contact) {
          return null
        }

        return {
          name: 'contacts-contact-item',
          params: {
            id: String(contact.id)
          }
        }
      },
      formParams: {
        label: 'fullname',
        reduce: (opt: KwixeoInterface.GenericObject) => opt.id
      },
      filterParams: {
        optionLabel: 'fullname',
        value: 'id'
      },
      params: {
        ajax: '/customer/contact',
        lazyLoading: true
      }
    },
    {
      type: 'user',
      formSelectLabel: 'Utilisateur',
      label: 'Utilisateur',
      view: (teamUser: KwixeoFramework.TeamUser|null) => teamUser?.fullname,
      formParams: {
        label: 'fullname',
        reduce: (teamUser: KwixeoFramework.TeamUser) => teamUser.id
      },
      filterParams: {
        optionLabel: 'fullname',
        value: 'id'
      },
      params: {
        ajax: '/team/user',
        lazyLoading: true
      }
    },
    {
      type: 'commercial',
      formSelectLabel: 'Commercial',
      label: 'Commercial',
      view: (teamUser: KwixeoFramework.TeamUser|null) => teamUser?.fullname,
      formParams: {
        label: 'fullname',
        reduce: (teamUser: KwixeoFramework.TeamUser) => teamUser.id
      },
      filterParams: {
        optionLabel: 'fullname',
        value: 'id'
      },
      params: {
        ajax: '/team/user',
        lazyLoading: true
      }
    },
    {
      type: 'product',
      formSelectLabel: 'Produit',
      label: 'Produit',
      view: (product: KwixeoCatalog.Product|null) => product?.name,
      formParams: {
        label: 'name',
        reduce: (product: KwixeoCatalog.Product) => product.id
      },
      filterParams: {
        optionLabel: 'name',
        value: 'id'
      },
      params: {
        ajax: '/catalog/product',
        lazyLoading: true
      }
    },
    {
      type: 'service',
      formSelectLabel: 'Prestation',
      label: 'Prestation',
      view: (service: KwixeoCatalog.Service|null) => service?.name,
      formParams: {
        label: 'name',
        reduce: (service: KwixeoCatalog.Service) => service.id
      },
      filterParams: {
        optionLabel: 'name',
        value: 'id'
      },
      params: {
        ajax: '/catalog/service',
        lazyLoading: true
      }
    }
  ],
  usages: [
      { id: 'customer', label: 'Client' },
      { id: 'prospect', label: 'Prospect' },
      { id: 'provider', label: 'Fournisseur' },
      { id: 'subcontractor', label: 'Sous-traitant' },
      { id: 'contact', label: 'Contact' },
      { id: 'product', label: 'Produit' },
      { id: 'service', label: 'Prestation' },
      { id: 'quotation', label: 'Devis' },
      { id: 'command', label: 'Bon de commande' },
      { id: 'delivery', label: 'Bon de livraison' },
      { id: 'proforma', label: 'Facture proforma' },
      { id: 'invoice', label: 'Facture' },
      { id: 'credit', label: 'Avoir' },
      { id: 'opportunity', label: 'Opportunité' },
      { id: 'charge', label: 'Charge' },
      { id: 'contract', label: 'Contrat' },
      { id: 'ticket', label: 'Ticket' },
      { id: 'construction', label: 'Chantier' },
      { id: 'planification', label: 'Chantiers - Planifications' },
      { id: 'provider_command', label: 'Commande fournisseur' }
  ],
  withPlacementSelection: true
})
