import { Disconnect } from '@kwixeo/interface-vue'
import VueI18n from 'vue-i18n'

const menu: (i18n: VueI18n) => KwixeoInterface.UserMenu = (i18n) => ([
  {
    to: '/my-profile',
    label: i18n.t('interface.user.myProfile'),
    availableForAccountant: true
  },
  {
    to: '/configuration/team/informations',
    label: i18n.t('interface.user.myCompany'),
    userPermission: ['app-configuration', 1],
    availableForAccountant: true
  },
  {
    to: '/configuration/app-modules',
    label: i18n.t('interface.user.modules'),
    userPermission: ['manage-subscription', 1]
  },
  {
    to: '/configuration',
    label: i18n.t('interface.user.configuration'),
    userPermission: ['app-configuration', 1]
  },
  {
    to: '/app-support',
    label: 'Support technique',
    appActiveSupport: true
  },
  {
    label: i18n.t('interface.menuLabel.users'),
    to: '/configuration/users',
    userPermission: ['app-configuration', 1],
    onlyAccountant: true
  },
  {
    isSeparator: true,
    availableForAccountant: true
  },
  {
    component: Disconnect,
    availableForAccountant: true
  }
])

export default menu
