import Vue from 'vue'
import '@kwixeo/interface-vue/src/router/registerHooks'
import { Interface } from '@kwixeo/interface-vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from '@/lang/i18n'
import '../scss/index.scss'
import './init'
import './fontawesome'
import watchRoutes from '@/tracking'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(Interface),
  watch: {
    $route: watchRoutes
  }
}).$mount('#app')
