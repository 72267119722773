import Vue from 'vue'
import KwixeoTickets from '@kwixeo/tickets-vue/src/install'
import axios from '@/axios'
import router from '@/router'
import store from '@/store'
import i18n from '@/lang/i18n'

Vue.use(KwixeoTickets, {
  axios,
  router,
  store,
  i18n
})
