import VueI18n from 'vue-i18n'

const configMenu: (i18n: VueI18n) => KwixeoInterface.MainMenuEntries = i18n => ([
  {
    label: 'Espace configuration',
    path: '/configuration'
  },
  {
    label: i18n.t('interface.menuLabel.teamInformations'),
    path: '/configuration/team/informations',
    icon: ['fas', 'building'],
    keywords: ['adresse', 'siret', 'tva']
  },
  {
    label: i18n.t('interface.menuLabel.teamParams'),
    path: '/configuration/parameters',
    icon: ['fas', 'cogs'],
    keywords: ['devise', 'timezone'],
    searchLabel: 'Paramètres du compte'
  },
  {
    label: 'Modules',
    path: '/configuration/app-modules',
    icon: ['fas', 'cubes'],
    userPermission: ['manage-subscription', 1],
    keywords: ['modules', 'fonctionnalites'],
    searchLabel: 'Activation / Désactivation des modules'
  },
  {
    label: 'Connecteurs',
    path: '/configuration/plugins',
    icon: ['fas', 'plug'],
    keywords: ['connecteur', 'plugin', 'brevo'],
    searchLabel: 'Connecteurs',
    mainApp: true
  },
  {
    label: i18n.t('customFields.menu'),
    path: '/configuration/custom-fields',
    icon: ['fas', 'sliders-v-square'],
    keywords: ['champs', 'personnalises']
  },
  {
    label: 'Charges',
    icon: ['fas', 'truck-container'],
    appModule: 'charges',
    open: false,
    active: '/configuration/charges',
    items: [
      {
        label: i18n.t('charges.params.menu'),
        path: '/configuration/charges/params',
        keywords: ['charges', 'numerotation', 'achats'],
        searchLabel: 'Numérotation des charges'
      },
      {
        label: i18n.t('catalog.chargeCategory.menu'),
        path: '/configuration/charges/charge-categories',
        keywords: ['charges', 'categories', 'achats'],
        searchLabel: 'Catégories de charge'
      }
    ]
  },
  {
    label: 'Commissions',
    path: '/configuration/commissions',
    icon: ['fas', 'badge-percent'],
    appModule: 'sales-commissions'
  },
  {
    label: i18n.t('communication.menuTitle'),
    icon: ['fas', 'comments'],
    open: false,
    active: '/configuration/communication',
    items: [
      {
        label: i18n.t('communication.files.menu'),
        path: '/configuration/communication/files',
        keywords: ['fichiers', 'emails', 'lettres'],
        searchLabel: 'Bibliothèque de fichier'
      },
      {
        label: i18n.t('communication.letters.menu'),
        path: '/configuration/communication/letters',
        appModule: 'communication-letters',
        keywords: ['lettres'],
        searchLabel: 'Paramètres des lettres'
      },
      {
        label: i18n.t('communication.models.menu'),
        path: '/configuration/communication/models',
        keywords: ['modèles', 'emails', 'lettres'],
        searchLabel: 'Gestion des modèles d\'emails et de lettres'
      },
      {
        label: i18n.t('communication.params.menu'),
        path: '/configuration/communication/params',
        keywords: ['emails', 'lettres', 'modeles'],
        searchLabel: 'Utilisation des modèles d\'emails et de lettres'
      }
    ]
  },
  {
    label: 'Comptabilité',
    icon: ['fas', 'universal-access'],
    open: false,
    active: '/configuration/accounting',
    items: [
      {
        label: 'Exports comptable',
        path: '/configuration/accounting/fec',
        keywords: ['fichier', 'fec', 'export', 'comptable', 'comptabilite']
      },
      {
        label: 'Expert comptable',
        path: '/configuration/accounting/my-accountant',
        keywords: ['expert', 'comptable', 'acces']
      },
      {
        label: 'Clôture comptable',
        path: '/configuration/accounting/accounting-closing',
        keywords: ['cloture', 'comptable']
      }
    ]
  },
  {
    label: i18n.t('constructions.menuTitle'),
    icon: ['fas', 'traffic-cone'],
    path: '/configuration/constructions',
    keywords: ['chantier', 'construction'],
    searchLabel: 'Configuration de la gestion de chantier',
    appModule: 'construction'
  },
  {
    label: 'Contrats & abonnements',
    path: '/configuration/sales/contract-models',
    appModule: 'sales-contracts',
    icon: ['fas', 'file-contract'],
    keywords: ['contrats', 'abonnements', 'modèles']
  },
  {
    label: 'Garage',
    path: '/configuration/automotive',
    icon: ['fas', 'cars'],
    appModule: 'automotive'
  },
  {
    label: i18n.t('interface.menuLabel.historics'),
    path: '/configuration/historics',
    icon: ['fas', 'history'],
    keywords: ['historique', 'actions']
  },
  {
    label: i18n.t('interface.menuLabel.importsExports'),
    path: '/configuration/imports-exports',
    icon: ['fas', 'file-import'],
    keywords: ['imports', 'exports']
  },
  {
    label: i18n.t('sales.pdfModel.menu'),
    path: '/configuration/pdf-models',
    icon: ['fas', 'file-pdf'],
    keywords: ['modeles', 'ventes', 'pdf']
  },
  {
    label: i18n.t('interface.menuLabel.tags'),
    path: '/configuration/tags',
    icon: ['fas', 'tags'],
    keywords: ['mots', 'cles', 'mots-cles']
  },
  {
    label: i18n.t('opportunities.params.menuParent'),
    icon: ['fas', 'folders'],
    open: false,
    active: '/configuration/opportunities',
    appModule: 'opportunities',
    items: [
      {
        label: i18n.t('opportunities.params.menu'),
        path: '/configuration/opportunities/params',
        keywords: ['opportunites', 'tunnels', 'ventes'],
        searchLabel: 'Paramètres des opportunités'
      },
      {
        label: i18n.t('opportunities.funnels.menu'),
        path: '/configuration/opportunities/funnels',
        keywords: ['opportunites', 'tunnels', 'ventes']
      },
      {
        label: i18n.t('opportunities.lossReasons.menu'),
        path: '/configuration/opportunities/loss-reasons',
        keywords: ['opportunites', 'motif', 'perte']
      }
    ]
  },
  {
    label: i18n.t('interface.menuLabel.customizeApp'),
    path: '/configuration/app-customize',
    icon: ['fas', 'palette'],
    keywords: ['couleur', 'personnalisation'],
    searchLabel: 'Personnalisation de l\'interface'
  },
  {
    label: 'Signatures électronique',
    path: '/configuration/sign-params',
    icon: ['fas', 'signature'],
    keywords: ['signature', 'electronique'],
    appElectronicSign: true
  },
  {
    label: i18n.t('catalog.config.menu'),
    path: '/configuration/stock-params',
    icon: ['fas', 'box-open'],
    keywords: ['stock', 'catalogue'],
    searchLabel: 'Paramètres du stock',
    appModule: 'stock'
  },
  {
    label: 'Tâches',
    icon: ['fas', 'ballot-check'],
    appModule: 'task',
    path: '/configuration/tasks-categories',
    keywords: ['taches', 'categories'],
    searchLabel: 'Catégories des tâches'
  },
  {
    label: i18n.t('interface.menuLabel.tiers'),
    icon: ['fas', 'users-cog'],
    open: false,
    active: '/configuration/tiers',
    items: [
      {
        label: i18n.t('customers.contactActivity.menu'),
        path: '/configuration/tiers/contact-activities',
        keywords: ['tiers', 'clients', 'prospects', 'contacts', 'activites']
      },
      {
        label: i18n.t('interface.menuLabel.civilities'),
        path: '/configuration/tiers/civilities',
        keywords: ['tiers', 'clients', 'prospects', 'contacts', 'civilites']
      },
      {
        label: i18n.t('customers.contactFunction.menu'),
        path: '/configuration/tiers/contact-functions',
        keywords: ['contacts', 'fonctions', 'postes']
      },
      {
        label: i18n.t('customers.contactOrigin.menu'),
        path: '/configuration/tiers/contact-origins',
        keywords: ['tiers', 'clients', 'prospects', 'contacts', 'origines']
      },
      {
        label: i18n.t('customers.params.menu'),
        path: '/configuration/tiers/params',
        keywords: ['tiers', 'clients', 'prospects', 'contacts', 'fournisseurs', 'sous-traitants', 'soustraitants'],
        searchLabel: 'Numérotation des tiers et gestion des doublons'
      },
      {
        label: i18n.t('customers.customerType.menu'),
        path: '/configuration/tiers/types',
        keywords: ['tiers', 'clients', 'prospects', 'contacts', 'fournisseurs', 'sous-traitants', 'soustraitants', 'types']
      }
    ]
  },
  {
    label: i18n.t('tickets.menuLabel'),
    icon: ['fas', 'ticket-alt'],
    open: false,
    active: '/configuration/tickets',
    appModule: 'tickets',
    items: [
      {
        label: i18n.t('tickets.configuration.menuLabel'),
        path: '/configuration/tickets/configuration',
        keywords: ['ticket'],
        searchLabel: 'Configuration du ticketing'
      },
      {
        label: i18n.t('tickets.tags.menuLabel'),
        path: '/configuration/tickets/tags',
        keywords: ['ticket', 'mots', 'cles', 'mots-cles'],
        searchLabel: 'Gestion des mots-clés des tickets'
      },
      {
        label: i18n.t('tickets.categories.menuLabel'),
        path: '/configuration/tickets/categories',
        keywords: ['ticket', 'categorie'],
        searchLabel: 'Gestion des catégories des tickets'
      },
      {
        label: i18n.t('tickets.templates.menuLabel'),
        path: '/configuration/tickets/templates',
        keywords: ['ticket', 'modele', 'template'],
        searchLabel: 'Gestion des modèles de réponse des tickets'
      }
    ]
  },
  {
    label: i18n.t('sales.menuTitle'),
    icon: ['fas', 'shopping-cart'],
    open: false,
    active: '/configuration/sales',
    items: [
      {
        label: i18n.t('catalog.params.menu'),
        path: '/configuration/sales/catalog',
        keywords: ['catalogue', 'produits', 'prestations', 'numerotation', 'references'],
        searchLabel: 'Numérotation des produits et services'
      },
      {
        label: i18n.t('sales.saleCategory.menu'),
        path: '/configuration/sales/sales-categories',
        keywords: ['ventes', 'categories']
      },
      {
        label: i18n.t('sales.saleTerm.menu'),
        path: '/configuration/sales/sale-terms',
        keywords: ['cgv', 'conditions', 'ventes']
      },
      {
        label: i18n.t('sales.account.menu'),
        path: '/configuration/sales/accounts',
        keywords: ['ventes', 'paiements', 'comptes', 'bancaire']
      },
      {
        label: i18n.t('sales.paymentTerm.menu'),
        path: '/configuration/sales/payment-terms',
        keywords: ['ventes', 'conditions', 'paiements']
      },
      {
        label: i18n.t('sales.paymentMethod.menu'),
        path: '/configuration/sales/payment-methods',
        keywords: ['ventes', 'moyens', 'paiements']
      },
      {
        label: 'Mandats SEPA',
        path: '/configuration/sales/sepa',
        appModule: 'sales-sepa',
        keywords: ['sepa', 'prélèvements']
      },
      {
        label: 'Paiements en ligne',
        path: '/configuration/sales/stripe',
        appModule: 'stripe',
        keywords: ['ventes', 'paiement', 'ligne', 'stripe']
      },
      {
        label: i18n.t('sales.params.menu'),
        path: '/configuration/sales/params',
        keywords: ['ventes', 'numérotation', 'pdf', 'options'],
        searchLabel: 'Ventes : paramètres par défaut, options de saisie et d\'affichage du PDF et numérotation des documents'
      },
      {
        label: 'Prix personnalisés',
        path: '/configuration/sales/custom-prices',
        appModule: 'catalog-custom-prices',
        keywords: ['ventes', 'perso', 'prix']
      },
      {
        label: i18n.t('sales.grant.menu'),
        path: '/configuration/sales/grants',
        appModule: 'sales-grants',
        keywords: ['ventes', 'subventions'],
        searchLabel: 'Gestion des subventions'
      },
      {
        label: i18n.t('sales.recoveryInvoice.config.menu'),
        path: '/configuration/sales/recovery-invoice-steps',
        appModule: 'sales-recovery',
        keywords: ['ventes', 'relances', 'impayées']
      },
      {
        label: i18n.t('sales.vatRate.menu'),
        path: '/configuration/sales/vat-rates',
        keywords: ['taux', 'tva']
      },
      {
        label: i18n.t('sales.unit.menu'),
        path: '/configuration/sales/units',
        keywords: ['unites']
      },
      {
        appModule: 'catalog-variations',
        label: i18n.t('catalog.productVariations.menu'),
        path: '/configuration/sales/product-variations',
        keywords: ['catalogue', 'produits', 'variations']
      }
    ]
  },
  {
    label: i18n.t('interface.menuLabel.users'),
    path: '/configuration/users',
    icon: ['fas', 'users'],
    keywords: ['utilisateurs', 'users'],
    userPermission: ['manage-users', 1]
  },
  {
    label: 'Mon abonnement',
    path: '/configuration/subscription/manage',
    icon: ['fas', 'credit-card'],
    userPermission: ['manage-subscription', 1],
    keywords: ['abonnement', 'factures'],
    appActivePayment: true
  },
  {
    label: 'Parrainage',
    path: '/configuration/subscription/sponsorship',
    icon: ['fas', 'chart-network'],
    userPermission: ['manage-subscription', 1],
    keywords: ['parrainage'],
    appActivePayment: true,
    appActiveSponsorship: true
  },
  {
    label: i18n.t('interface.menuLabel.api'),
    path: '/configuration/api-token',
    icon: ['fas', 'key'],
    keywords: ['api', 'token'],
    mainApp: true
  }
])

export default configMenu
