import Vue from 'vue'
import KwixeoConstructions from '@kwixeo/constructions-vue/src/install'
import axios from '@/axios'
import store from '@/store'
import router from '@/router'
import i18n from '@/lang/i18n'

Vue.use(KwixeoConstructions, {
  axios,
  store,
  router,
  i18n
})
