import { Component, Mixins, Watch } from 'vue-property-decorator'
import { BFormGroup, BFormRow, BCol, BButton, BListGroup, BListGroupItem } from 'bootstrap-vue'
import { TeamFormMixin, Spinner } from '@kwixeo/interface-vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { KForm, KFormAddress, KFormInput, KFormPhone } from '@kwixeo/form-vue'
import { mapActions, mapState } from 'vuex'
import Form from '@kwixeo/form-vue/dist/components/Form/Form'
import axios, { AxiosResponse } from 'axios'
import localAxios from '@/axios'

@Component({
  components: {
    BFormGroup, BFormRow, BCol, BButton,
    BListGroup, BListGroupItem,
    FontAwesomeIcon,
    Spinner,
    KForm, KFormInput, KFormAddress, KFormPhone,
  },
  computed: {
    ...mapState('globalDatas', ['countries'])
  },
  methods: {
    ...mapActions('globalDatas', ['loadCountries'])
  }
})
export default class TeamInformations extends Mixins(TeamFormMixin) {
  loadCountries!: (force?: boolean) => void
  countries!: KwixeoFramework.Countries

  private initialLoading = true
  private loading = false
  private searchCompanyResults: KwixeoCustomers.SiretApiResults|null = null
  private manualDatas = false
  private showMoreData = false

  private address: Partial<KwixeoFramework.BaseAddress> = {
    road1: null,
    road2: null,
    road3: null,
    postal_code: null,
    town: null,
    country: null
  }

  async mounted (): Promise<void> {
    await this.loadCountries()
    this.setLocalTeam(this.team)

    this.initialLoading = true
    await this.searchCompany()
    this.initialLoading = false
    if (this.searchCompanyResults?.length === 0) {
      // this.$emit('next')
      this.manualDatas = true
    }
  }

  get formAction (): string {
    return '/welcome/team?with_file=1'
  }

  private setAddress (): void {
    const team = this.localTeam
    const country = team.country
    this.address = {
      road1: team.road1,
      road2: team.road2,
      road3: team.road3,
      postal_code: team.postal_code,
      town: team.town,
      country: country ?? this.countries.find(country => country.code === 'FR')
    }
  }

  @Watch('address')
  private addressUpdated (): void {
    const team = this.localTeam
    const address = this.address
    team.road1 = address.road1
    team.road2 = address.road2
    team.road3 = address.road3
    team.postal_code = address.postal_code
    team.town = address.town
    team.country = address.country
  }

  @Watch('team')
  protected setLocalTeam (team: KwixeoFramework.Team): void {
    this.localTeam = JSON.parse(JSON.stringify(team))
    this.setAddress()
  }

  public submit (): void {
    const form = (this.$refs.form as Form)
    if (form) {
      form.submit()
    } else {
      this.$emit('next')
    }
  }

  private submitComplete (response: AxiosResponse): void {
    this.formSubmitted(response)
    this.$emit('next')
  }

  private async searchCompany (): Promise<void> {
    const name = this.localTeam.company_name
    if ((name?.length ?? 0) < 3) {
      this.searchCompanyResults = null
      return
    }

    await axios.get(this.$kwCustomers.sireneApiUrl + '/siret', {
        headers: {
          Authorization: 'Bearer ' + this.$kwCustomers.sireneApitoken
        },
        withCredentials: true,
        params: {
          siren_or_name: name,
          per_page: 3
        }
      })
      .then(response => {
        this.searchCompanyResults = response.data.data
      })
      .catch(() => {
        this.initialLoading = false
        this.manualDatas = true
      })
  }

  private uppercaseFirstLetter (term: string|null): string|null {
    if (!term) {
      return null
    }
    return term.charAt(0).toUpperCase() + term.slice(1).toLowerCase()
  }

  private async selectCompany (company: KwixeoCustomers.SiretApiResult): Promise<void> {
    const road: string[] = []
    if (company.numeroVoieEtablissement) road.push(company.numeroVoieEtablissement)
    if (company.indiceRepetitionEtablissement) road.push(company.indiceRepetitionEtablissement)
    if (company.typeVoieEtablissement) road.push(this.uppercaseFirstLetter(company.typeVoieEtablissement) as string)
    if (company.libelleVoieEtablissement) road.push(this.uppercaseFirstLetter(company.libelleVoieEtablissement) as string)

    this.loading = true
    await localAxios.put(this.formAction, {
      company_name: company.unite_legale?.denominationUniteLegale,
      road1: road.join(' '),
      postal_code: company.codePostalEtablissement,
      town: this.uppercaseFirstLetter(company.libelleCommuneEtablissement),
      country: 'FR',
      company_siret: company.siret,
      company_naf: company.activitePrincipaleEtablissement?.replace('.', '')
    }).then(response => {
      this.formSubmitted(response)
      this.$emit('next')
      this.loading = false
      this.manualDatas = true
    })

    this.localTeam.company_naf
  }
}
