import { Component, Mixins, Watch } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { KForm, KFormInput } from '@kwixeo/form-vue'
import groupBy from 'lodash/groupBy'
import { BCol, BFormRow } from 'bootstrap-vue'
import { DateMixin, NumberMixin, Spinner } from '@kwixeo/interface-vue'
import axios from '@/axios'
import { mapGetters } from 'vuex'

@Component({
  components: {
    FontAwesomeIcon,
    KForm, KFormInput,
    Spinner,
    BFormRow, BCol
  },
  computed: {
    ...mapGetters('user', ['can'])
  }
})
export default class MainSearch extends Mixins(DateMixin, NumberMixin) {
  can!: (key: string, level: number) => boolean

  private activeSearch = false
  private loading = false
  private timeout: number|null = null
  private searchTerm: string|null = null
  private results: KwixeoApp.MainSearchResults|null = null

  private appElement: HTMLElement|null = null

  mounted (): void {
    this.appElement = document.querySelector('#app')
  }

  get resultsByTypes (): KwixeoApp.MainSearchResultsByTypes {
    return groupBy(this.results?.datas, result => result.type)
  }

  private openSearch (): void {
    this.activeSearch = true
    this.results = null
    this.loading = false

    this.$nextTick(() => {
      const input = this.$el.querySelector('#input-main-search') as HTMLFormElement
      input.focus()
    })
  }

  @Watch('activeSearch')
  private onSearchState (state: boolean): void {
    if (state) {
      document.addEventListener('keydown', this.onKeyDown)
      this.appElement?.classList.add('global-search-active')
    } else {
      document.removeEventListener('keydown', this.onKeyDown)
      this.appElement?.classList.remove('global-search-active')
    }
  }

  private clearTimeout (): void {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  private hasSearchTerm (): boolean {
    return this.searchTerm !== null && this.searchTerm !== ''
  }

  @Watch('searchTerm')
  private watchSearchTerm (): void {
    this.clearTimeout()

    if (!this.hasSearchTerm()) {
      this.results = null
      this.loading = false
      return
    }

    this.timeout = setTimeout(this.search, 350)
  }

  private onKeyDown (e: KeyboardEvent): void {
    if (e.key === 'Escape') {
      this.activeSearch = false
    }
  }

  @Watch('activeSearch')
  private watchActiveSearch (): void {
    if (!this.activeSearch) {
      this.clearTimeout()
      this.results = null
      this.loading = false
      this.searchTerm = null
      return
    }
  }

  private async search (): Promise<void> {
    if (!this.hasSearchTerm()) {
      return
    }

    this.loading = true
    await axios.get('/search', {
      params: {
        search: this.searchTerm ? encodeURIComponent(this.searchTerm) : null,
        include: [
          { type: 'contact', include: ['customers'] },
          { type: 'opportunity', include: ['customer'] },
          { type: 'quotation', include: ['customer', 'currency'] },
          { type: 'command', include: ['customer', 'currency'] },
          { type: 'delivery', include: ['customer', 'currency'] },
          { type: 'proforma', include: ['customer', 'currency'] },
          { type: 'invoice', include: ['customer', 'currency'] },
          { type: 'credit', include: ['customer', 'currency'] }
        ]
      }
    })
      .then(response => {
        this.results = response.data
      })
      .finally(() => {
        this.loading = false
      })
  }

  private async goToElement (data: KwixeoApp.MainSearchResult): Promise<void> {
    const element = data.data
    switch (data.type) {
      case 'customer':
        // eslint-disable-next-line no-case-declarations
        const references = data.data.reference
        // eslint-disable-next-line no-case-declarations
        let type: string
        if (references.provider) {
          type = 'provider'
        } else if (references.subcontractor) {
          type = 'subcontractor'
        } else if (references.customer) {
          type = 'customer'
        } else {
          type = 'prospect'
        }

        await this.$router.push({
          name: 'contacts-customer-item',
          params: {
            type,
            id: String(element.id)
          }
        })
        break
      case 'contact':
        await this.$router.push({
          name: 'contacts-contact-item',
          params: {
            id: String(element.id)
          }
        })
        break
      default:
        await this.$router.push({
          name: data.type + '-item',
          params: {
            id: String(element.id)
          }
        })
        break
    }

    this.activeSearch = false
  }

  // Hightlight searched term
  private hightlight (text: string): string {
    text = this.sanitize(text)

    if (this.searchTerm) {
      this.searchTerm.split(' ')
        .filter(term => term !== '' && term !== null)
        .forEach(term => {
          text = text.replaceAll(term, '<span class="hightlight">' + term + '</span>')
        })
    }

    return text
  }

  // sanitize HTML
  private sanitize (text: string): string {
    const parser = new DOMParser()
    const doc = parser.parseFromString(text, 'text/html')
    const body = doc.body

    const scripts = body.querySelectorAll('script');
    for (const script of scripts) {
      script.remove();
    }

    const nodes = body.children;
    for (const node of nodes) {
      const atts = node.attributes;
      for (const { name, value } of atts) {
        const val = value.replace(/\s+/g, '').toLowerCase()
        if (name.startsWith('on')
          || ['src', 'href', 'xlink:href'].includes(name)
          || val.includes('javascript:')
          || val.includes('data:text/html'))
        {
          node.removeAttribute(name)
        }
      }
    }

    return body.innerHTML
  }
}
