import { Component, Vue } from 'vue-property-decorator'
import { Spinner } from '@kwixeo/interface-vue'
import { mapActions } from 'vuex'

@Component({
  components: {
    Spinner
  },
  methods: {
    ...mapActions('auth', ['setToken']),
  }
})
export default class RegisterLogin extends Vue {
  setToken!: (token: KwixeoFramework.AuthToken|null) => void

  async mounted (): Promise<void> {
    // http://localhost:8080/register/login/243%7Claravel_sanctum_LVy5khHvsBq553J7oFRUHVjb3aDgfRZLydiLkMfk91dac8ee/2023-09-18%2000:03:46
    this.setToken({
      access_token: this.$route.params.token,
      expires_at: this.$route.params.expire
    })
    await Vue.prototype.$kwInterface.goToHome(this.$router, this.$store)
  }
}
