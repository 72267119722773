import Vue from 'vue'
import Vuex from 'vuex'
import { BroadcastStore } from '@kwixeo/interface-vue'
import { AppStore, DynamicModalsStore, GlobalDatasStore, UserStore } from '@kwixeo/interface-vue'
import { CustomersStore } from '@kwixeo/customers-vue'
import { CustomFieldsStore } from '@kwixeo/custom-fields-vue'
import { TasksStore } from '@kwixeo/tasks-vue'
import { CatalogStore } from '@kwixeo/catalog-vue'
import { CalendarsStore } from '@kwixeo/calendars-vue'
import { CommunicationStore } from '@kwixeo/communication-vue'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app: AppStore,
    dynamicModals: DynamicModalsStore,
    globalDatas: GlobalDatasStore,
    user: UserStore,
    customers: CustomersStore,
    customFields: CustomFieldsStore,
    tasks: TasksStore,
    calendars: CalendarsStore,
    communication: CommunicationStore,
    catalog: CatalogStore
  },
  plugins: [
    BroadcastStore({
      statesRemove: ['mailForms'],
      ignoreMutations: [
        'communication/addMailForm', 'communication/updateMailForm', 'communication/removeMailForm',
        'communication/clearMailForm', 'communication/checkOpenedForm'
      ]
    })
  ]
})
