import Vue from 'vue'
import i18n from '@/lang/i18n'
import axios from '@/axios'
import store from '@/store'
import { KwixeoForm } from '@kwixeo/form-vue'
import { Login, formatDate } from '@kwixeo/interface-vue'
import { validationRules as CommunicationRules } from '@kwixeo/communication-vue'
import { validationRules as SalesRules } from '@kwixeo/sales-vue'
import { validationRules as TicketsRules } from '@kwixeo/tickets-vue'

Vue.use(KwixeoForm, {
  hereApiKey: process.env.VUE_APP_HERE_API_KEY,
  i18n,
  axios,
  form: {
    parent: '#page'
  },
  addressValuesMap: {
    road: 'road1',
    postalCode: 'postal_code',
    city: 'town'
  },
  validationRules: {
    ...CommunicationRules,
    ...SalesRules,
    ...TicketsRules
  },
  loginComponent: Login,
  loginAttributes: () => {
    const user = store.state.user.user
    return {
      redirectAfterLogin: false,
      email: user?.email,
      readonlyEmail: user !== null,
      canCreateAccount: false,
      canResetPassword: false
    }
  },
  dateFormat: formatDate
})
