import { Component, Mixins } from 'vue-property-decorator'
import { BCol, BRow } from 'bootstrap-vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapState } from 'vuex'
import forEach from 'lodash/forEach'
import { Spinner, TeamConfigMixin } from '@kwixeo/interface-vue'
import axios from '@/axios'

@Component({
  computed: {
    ...mapState('app', ['app'])
  },
  components: {
    BRow, BCol,
    FontAwesomeIcon,
    Spinner
  }
})
export default class Themes extends Mixins(TeamConfigMixin) {
  app!: KwixeoFramework.App

  private selected = 0
  private loading = false

  private themes = [
    { // Default
      app_color_sidebar_bg: '#333333',
      app_color_sidebar_menu_hover_bg: '#4c4c4c',
      app_color_sidebar_group_active_bg: '#4c4c4c',
      app_color_sidebar_element_active_bg: '#dc4146',
      app_color_configuration_sidebar_bg: '#333333',
      app_color_header_menu_active_color: '#dc4146',
      app_color_primary: '#dc4146',
      app_color_btn_primary_hover: '#d0262c',
      app_color_link_color: '#dc4146',
      app_color_link_color_hover: '#b02025',
    },
    { // Blue
      app_color_sidebar_bg: '#4285f4',
      app_color_sidebar_menu_hover_bg: '#3871cc',
      app_color_sidebar_group_active_bg: '#2d599e',
      app_color_sidebar_element_active_bg: '#24457b',
      app_color_configuration_sidebar_bg: '#4285f4',
      app_color_header_menu_active_color: '#4285f4',
      app_color_primary: '#4285f4',
      app_color_btn_primary_hover: '#2d599e',
      app_color_link_color: '#4285f4',
      app_color_link_color_hover: '#2d599e',
    },
    { // Orange
      app_color_sidebar_bg: '#f09e0e',
      app_color_sidebar_menu_hover_bg: '#bc7b0c',
      app_color_sidebar_group_active_bg: '#bc7b0b',
      app_color_sidebar_element_active_bg: '#9a6509',
      app_color_configuration_sidebar_bg: '#f09e0e',
      app_color_header_menu_active_color: '#f09e0e',
      app_color_primary: '#f09e0e',
      app_color_btn_primary_hover: '#bc7b0c',
      app_color_link_color: '#f09e0e',
      app_color_link_color_hover: '#bc7b0c',
    },
    { // Red
      app_color_sidebar_bg: '#dc4146',
      app_color_sidebar_menu_hover_bg: '#9f1e26',
      app_color_sidebar_group_active_bg: '#9f1e26',
      app_color_sidebar_element_active_bg: '#9f1e26',
      app_color_configuration_sidebar_bg: '#dc4146',
      app_color_header_menu_active_color: '#dc4146',
      app_color_primary: '#dc4146',
      app_color_btn_primary_hover: '#be272f',
      app_color_link_color: '#dc4146',
      app_color_link_color_hover: '#be272f',
    },
    { // Green
      app_color_sidebar_bg: '#55b423',
      app_color_sidebar_menu_hover_bg: '#40841b',
      app_color_sidebar_group_active_bg: '#40841b',
      app_color_sidebar_element_active_bg: '#40841b',
      app_color_configuration_sidebar_bg: '#55b423',
      app_color_header_menu_active_color: '#55b423',
      app_color_primary: '#55b423',
      app_color_btn_primary_hover: '#40841b',
      app_color_link_color: '#55b423',
      app_color_link_color_hover: '#40841b',
    },
    { // Purple
      app_color_sidebar_bg: '#a601ff',
      app_color_sidebar_menu_hover_bg: '#7100ad',
      app_color_sidebar_group_active_bg: '#7100ad',
      app_color_sidebar_element_active_bg: '#7100ad',
      app_color_configuration_sidebar_bg: '#a600ff',
      app_color_header_menu_active_color: '#a601ff',
      app_color_primary: '#a601ff',
      app_color_btn_primary_hover: '#7100ad',
      app_color_link_color: '#a601ff',
      app_color_link_color_hover: '#7100ad',
    },
  ]

  get activeTheme (): number {
    if (!this.teamConfigs.app_color_sidebar_bg) {
      return 0
    }
    return this.themes.findIndex(theme => theme.app_color_sidebar_bg === this.teamConfigs.app_color_sidebar_bg)
  }

  async mounted (): Promise<void> {
    this.selected = this.activeTheme
  }

  beforeDestroy (): void {
    if (this.activeTheme !== this.selected) {
      this.selectColor(this.activeTheme)
    }
  }

  private selectColor (index: number): void {
    this.selected = index
    forEach(this.themes[index], (color: string, key: string) => {
      this.applyColor(key.substr(10), color)
    })
  }

  private applyColor (key: string, color: string|null): void {
    document.documentElement.style.setProperty('--' + key.replaceAll('_', '-'), color)
  }

  public submit (): void {
    if (this.selected === this.activeTheme) {
      this.$emit('next')
    } else {
      this.saveColors()
    }
  }

  private async saveColors (): Promise<void> {
    this.loading = true
    await axios.put(this.formAction, {
      configs: this.themes[this.selected]
    }).then(this.formSubmitted)
    this.loading = false
    this.$emit('next')
  }
}
