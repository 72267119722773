import store from '@/store'
import { Route } from 'vue-router'
import axios from '@/axios'

const watchRoutes = (to: Route): void => {
  if (store.getters['auth/isAuth']) {
    const team = store.getters['user/team'] as KwixeoFramework.Team
    if (team) {
      const sales = team.app_modules?.find(module => module.key === 'sales-gestionco');
      if (sales && sales.is_trying) {
        axios.post('/tracking', {
          type: 'route',
          name: to.name,
          path: to.path
        })
      }
    }
  }
}

export default watchRoutes
