import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import RegisterLogin from '../views/RegisterLogin/RegisterLogin.vue'
// import PageCustomizeView from '../views/PageCustomizeView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/register/login/:token/:expire',
    name: 'register-login',
    component: RegisterLogin,
    meta: {
      isPublic: true,
      onlyGuest: true
    }
  }/*,
  {
    path: '/page-customize',
    name: 'page-customize',
    component: PageCustomizeView
  }*/
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
