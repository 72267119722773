import { Component, Vue } from 'vue-property-decorator'
import VueI18n from 'vue-i18n'
import { mapGetters } from 'vuex'
import { PageHeader } from '@kwixeo/interface-vue'
import { KFormInput } from '@kwixeo/form-vue'
import { BCard, BCol, BRow } from 'bootstrap-vue'
import { checkMenuModules } from '@kwixeo/subscriptions-vue'

@Component({
  computed: {
    ...mapGetters('user', ['can'])
  },
  components: {
    PageHeader,
    KFormInput,
    BRow, BCol, BCard
  }
})
export default class Configuration extends Vue {
  can!: (key: string, level: number) => boolean

  private searchTerm: string|null = null

  get menu (): KwixeoInterface.MainMenuEntries {
    const menu = this.$kwInterface.configMenu as ((i18n: VueI18n) => KwixeoInterface.MainMenuEntries)
    return menu(this.$i18n).filter(entry =>  {
      if (entry.appModule && !checkMenuModules(entry, this.$store)) {
        return false
      }
      if (entry.userPermission && !this.can(entry.userPermission[0], entry.userPermission[1])) {
        return false
      }
      return true
    })
  }

  get menuCategories (): KwixeoInterface.MainMenuEntries {
    return this.menu.filter(entry => 'items' in entry)
  }

  get menuAlone (): KwixeoInterface.MainMenuEntries {
    return this.menu.filter(entry => !('items' in entry) && 'path' in entry && entry.path !== '/configuration')
  }

  get plainMenu(): KwixeoInterface.MainMenuEntries {
    const menu: KwixeoInterface.MainMenuEntries = []
    this.menuCategories.forEach(entry => {
      if ('items' in entry) {
        menu.push(...entry.items)
      }
    })
    menu.push(...this.menuAlone)

    return menu
      .sort((a, b) => {
      if(a.label < b.label) return -1;
      else if (a.label > b.label) return 1;
      else return 0;
    })
  }

  get searchResults (): KwixeoInterface.MainMenuEntries {
    if (!this.searchTerm) {
      return []
    }

    const terms = this.searchTerm.split(' ').map(term => term.replaceAll('+', ' '))
    return this.plainMenu.filter(menu => {
      if (!menu.keywords) {
        return false
      }
      return menu.keywords.some(k => {
        return terms.findIndex(term => {
          return k.indexOf(term.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
        }) >= 0
      })
    })
  }
}
