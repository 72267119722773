import Vue from 'vue'
import KwixeoCustomers from '@kwixeo/customers-vue/src/install'
import { CustomerSpeedItem, ContactSpeedItem } from '@kwixeo/customers-vue'
import store from '@/store'
import router from '@/router'
import axios from '@/axios'
import i18n from '@/lang/i18n'

Vue.use(KwixeoCustomers, {
  axios,
  store,
  router,
  i18n,
  sireneApitoken: process.env.VUE_APP_SIRENE_API_TOKEN,
  sireneApiUrl: process.env.VUE_APP_SIRENE_API_URL
})

Vue.component('CustomerSpeedItem', CustomerSpeedItem)
Vue.component('ContactSpeedItem', ContactSpeedItem)
