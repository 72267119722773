<template>
  <div>
    <UserProfile />

    <b-tabs class="tabs-card mt-5" v-if="!team.disable_app && !can('constructionInterface', 1)">
      <b-tab
        v-if="app.is_main"
        title="Synchronisation"
        lazy
      >
        <b-row class="user-syncs">
          <b-col cols="auto">
            <PluginCardOutlook />
          </b-col>
          <b-col cols="auto">
            <PluginCardGoogle />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Expéditeurs" lazy>
        <SenderList />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { UserProfile } from '@kwixeo/interface-vue'
import { BTabs, BTab, BRow, BCol } from 'bootstrap-vue'
import { SenderList } from '@kwixeo/communication-vue'
import { PluginCard as PluginCardOutlook } from '@kwixeo/outlook-vue'
import { PluginCard as PluginCardGoogle } from '@kwixeo/google-vue'
import { mapGetters, mapState } from 'vuex'

@Component({
  components: {
    UserProfile,
    SenderList,
    PluginCardOutlook,
    PluginCardGoogle,
    BTabs, BTab, BRow, BCol
  },
  computed: {
    ...mapGetters('user', ['team', 'can']),
    ...mapState('app', ['app']),
  }
})
export default class UserProfileView extends Vue {
}
</script>
