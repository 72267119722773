import { Component, Vue } from 'vue-property-decorator'
import { KForm, KFormInput } from '@kwixeo/form-vue'
import Form from '@kwixeo/form-vue/dist/components/Form/Form'
@Component({
  components: {
    KForm, KFormInput
  }
})
export default class Modules extends Vue {
  private emails: string[] = []

  public submit (): void {
    if (this.validEmails.length > 0) {
      (this.$refs.form as Form).submit()
    } else {
      this.submitComplete()
    }
  }

  private cleanEmail (): void {
    this.emails = this.emails
      .filter((email, index) => email !== null && email !== '' && this.emails.indexOf(email) === index)
  }

  get validEmails (): string[] {
    return this.emails.filter(email => email !== null && email !== '')
  }

  private submitComplete (): void {
    this.$emit('next')
  }
}
