import Vue from 'vue'
import KwixeoHelp from '@kwixeo/help-vue/src/install'
import router from '@/router'
import axios from '@/axios'
import store from '@/store'
import i18n from '@/lang/i18n'

Vue.use(KwixeoHelp, {
  axios,
  store,
  router,
  i18n,
  apiUrl: process.env.VUE_APP_API_URL
})
