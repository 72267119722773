import Vue from 'vue'
import KwixeoOutlook from '@kwixeo/outlook-vue/src/install'
import store from '@/store'
import router from '@/router'
import axios from '@/axios'
import i18n from '@/lang/i18n'

Vue.use(KwixeoOutlook, {
  logo: require('@/assets/outlook.jpg'),
  axios,
  store,
  router,
  i18n
})
