import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import { TranslateResult } from 'vue-i18n'
import moment from 'moment'
import { AxiosResponse } from 'axios'
import { BFormGroup, BAlert, VBToggle, BCard, BCardHeader, BCardBody, BCollapse, BButton, BRow, BCol, BFormRadio, BFormRadioGroup } from 'bootstrap-vue'
import { KForm, KFormInput, KFormCheckbox, KFormCheckboxGroup, KFormSelect } from '@kwixeo/form-vue'
import { Spinner, FormModal } from '@kwixeo/interface-vue'

@Component({
  methods: {
    ...mapActions('globalDatas', ['loadUserRoles', 'loadUserPermissions', 'loadTeamUsers', 'setTeamUser'])
  },
  computed: {
    ...mapState('globalDatas', ['userRoles', 'userPermissions', 'teamUsers']),
    ...mapGetters('user', ['teamUser'])
  },
  components: {
    FormModal,
    Spinner,
    BFormGroup, BAlert, BCard, BCardHeader, BCardBody, BCollapse, BButton, BRow, BCol, BFormRadio, BFormRadioGroup,
    KForm, KFormInput, KFormCheckbox, KFormCheckboxGroup, KFormSelect
  },
  directives: {
    'b-toggle': VBToggle
  }
})
export default class UsersForm extends Vue {
  loadUserRoles!: (force?: boolean) => void
  loadUserPermissions!: (force?: boolean) => void
  loadTeamUsers!: (force?: boolean) => void
  userRoles!: KwixeoFramework.UserRoles
  userPermissions!: KwixeoFramework.UserPermissionCategories
  teamUsers!: KwixeoFramework.TeamUsers
  setTeamUser!: (teamUser: KwixeoFramework.TeamUser) => void
  teamUser!: KwixeoFramework.TeamUser

  @Prop({ default: null }) value!: KwixeoFramework.TeamUser|null
  @Prop({ required: true }) show!: boolean

  private localValue: Partial<KwixeoFramework.TeamUser> = {
    permissions: {},
    team_users: []
  }

  private invitationLoading = false

  async mounted (): Promise<void> {
    await this.loadUserPermissions()
    await this.loadUserRoles()
    await this.loadTeamUsers()
  }

  get modalTitle (): TranslateResult {
    return this.$t('interface.users.' + (this.value?.id ? 'editTitle' : 'newTitle'))
  }

  get computedTeamUsers (): KwixeoFramework.TeamUsers {
    return this.teamUsers.filter(user => !this.localValue.id || this.localValue.id !== user.id)
  }

  // Return form action
  get formAction (): string {
    let url = '/team/user'
    if (this.localValue.id) {
      url += '/' + this.localValue.id
    }
    return url
  }

  // Return form url
  get formMethod (): string {
    return this.localValue.id ? 'PUT' : 'POST'
  }

  get invitationStatus (): false|string {
    const invitation = this.localValue.invitation
    if (!invitation) {
      return false
    } else if (invitation.refused_at) {
      return 'refused'
    } else if (moment(invitation.expire_at).isBefore()) {
      return 'expired'
    } else {
      return 'wait'
    }
  }

  get constructionPermissions (): KwixeoFramework.UserPermissions {
    return (this.userPermissions.find(category => category.key === 'construction') as KwixeoFramework.UserPermissionCategory).permissions
  }

  // Watch v-model value
  @Watch('value', { immediate: true })
  private watchValue (value: KwixeoFramework.TeamUser|null): void {
    const localValue = value ? JSON.parse(JSON.stringify(value)) : {}
    if (!localValue.permissions) {
      const permissions: KwixeoFramework.TeamUserPermissions = {}
      this.userPermissions.forEach(category => {
        category.permissions.forEach(permission => {
          permissions[permission.key] = permission.defaultLevel.value
        })
      })
      localValue.permissions = permissions
    }
    this.localValue = localValue
  }

  @Watch('localValue.roles')
  private watchRoles (): void {
    if (!this.localValue || !this.localValue.permissions) {
      return
    }
    const roles = this.localValue.roles
    if (roles?.length !== 1 || !roles.includes('technical')) {
      this.localValue.permissions['constructionInterface'] = 0
    }
  }

  @Emit('updated')
  private submitComplete (response: AxiosResponse): KwixeoFramework.TeamUser {
    const teamUser = response.data as KwixeoFramework.TeamUser
    this.setTeamUser(teamUser)
    if (teamUser.id === this.teamUser.id) {
      this.$store.dispatch('user/getUserFromApi')
    }
    return response.data
  }

  // Resend the user invitation
  private async resendInvitation (): Promise<void> {
    this.invitationLoading = true
    await this.$kwInterface.axios.post('/team/user/' + this.localValue.id + '/resend_invitation')
      .then(response => {
        this.submitComplete(response)
      })
      .catch(error => {
        throw new Error(error)
      })
      .finally(() => {
        this.invitationLoading = false
      })
  }
}
