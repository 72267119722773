import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import { BFormRadioGroup, BRow, BCol, BButton, BOverlay } from 'bootstrap-vue'
import { Spinner } from '@kwixeo/interface-vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

@Component({
  components: {
    BFormRadioGroup, BRow, BCol, BButton, BOverlay,
    Spinner,
    FontAwesomeIcon
  },
  methods: {
    ...mapActions('subscriptions', ['loadAppModules', 'setModule']),
    ...mapActions('user', ['setTeam'])
  },
  computed: {
    ...mapGetters('user', ['team']),
    ...mapState('subscriptions', ['appModules']),
  },
})
export default class Modules extends Vue {
  loadAppModules!: (force?: boolean) => Promise<void>
  appModules!: KwixeoSubscriptions.Modules
  setModule!: (module: KwixeoSubscriptions.Module) => void
  setTeam!: (team: KwixeoFramework.Team) => void
  team!: KwixeoFramework.Team

  private loading = false
  private formLoading = false
  private showAllModules = false

  private onOff = [
    { value: true, text: 'Oui', class: null },
    { value: false, text: 'Non' }
  ]

  private mainModules = [
    'sales-commands',
    'sales-deliveries',
    'sales-contracts',
    'stock',
    'charges',
    'opportunities',
    'catalog-variations',
    'customers-prospects'
  ]

  private selectedModules: { [key: string]: boolean } = {}

  async mounted (): Promise<void> {
    this.loading = true
    await this.loadAppModules()
    this.loading = false

    const modules: { [key: string]: boolean } = {}
    this.appModules.forEach(module => {
      modules[module.key] = module.active
    })
    this.selectedModules = modules
  }

  get modules (): KwixeoSubscriptions.Modules {
    return this.appModules.filter(module => {
      if (!this.showAllModules) {
        return this.mainModules.includes(module.key)
      }
      return module.key !== 'sales-gestionco'
    }).sort((a, b) => {
      if (a.name < b.name) return -1
      else if (a.name > b.name) return 1
      else return 0
    })
  }

  private async changeModuleState (module: KwixeoSubscriptions.Module, status: boolean): Promise<void> {
    this.formLoading = true
    this.$kwSubscriptions.axios.post('/app/module/' + module.key + '/' + (status ? 'enable' : 'disable'))
      .then(response => {
        const module = response.data
        this.setModule(module)

        const team = JSON.parse(JSON.stringify(this.team)) as KwixeoFramework.Team
        const index = (team.app_modules as KwixeoSubscriptions.Modules).findIndex(val => val.key === module.key)
        if (index >= 0) {
          Vue.set(team.app_modules as KwixeoSubscriptions.Modules, index, module)
        }
        this.setTeam(team)

        this.$emit(status ? 'install' : 'uninstall', module)
      })
      .catch(error => {
        if (error.response.status === 402) {
          this.$emit('needPaid', module)
          return
        }
        throw new Error(error)
      })
      .finally(() => {
        this.formLoading = false
      })
  }
}
