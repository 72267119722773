import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { BButton } from 'bootstrap-vue'

@Component({
  computed: {
    ...mapState('app', ['app'])
  },
  components: {
    BButton
  }
})
export default class Final extends Vue {
  app!: KwixeoFramework.App
}
