const lang = {
  importsExports: {
    model: {
      customer: 'Clients',
      prospect: 'Prospects',
      provider: 'Fournisseurs',
      subcontractor: 'Sous-traitants',
      contact: 'Contacts',
      contactFunction: 'Fonctions des contacts',
      quotation: 'Devis',
      'quotation-full': 'Contenu des devis',
      delivery: 'Bons de livraison',
      'delivery-full': 'Contenu des bons de livraison',
      command: 'Bons de commande',
      'command-full': 'Contenu des bons de commande',
      invoice: 'Factures',
      'invoice-full': 'Contenu des factures',
      credit: 'Avoirs',
      'credit-full': 'Contenu des avoirs',
      proforma: 'Facture proforma',
      'proforma-full': 'Contenu des facture proforma',
      payment: 'Encaissements',
      'charge-payment': 'Paiements',
      charge: 'Charges',
      'charge-full': 'Contenu des charges',
      contract: 'Contrats',
      'contract-deadline': 'Échéances des contrats',
      task: 'Tâches',
      product: 'Produits',
      service: 'Prestations',
      providerReference: 'Produits - Références fournisseur',
      productVariations: 'Variations de produits',
      opportunity: 'Opportunités'
    }
  },
  notifications: {
    see: 'Voir',
    customer: {
      created: 'Le {type} a été créé',
      updated: 'Le {type} a été modifié',
      transformed: 'Le prospect a été transformé en client',
      createdByUser: 'a créé le {type}',
      updatedByUser: 'a modifié le {type}',
      transformedByUser: 'a transformé le prospect en client',
    },
    payment: {
      created: 'Un paiement d\'un montant de {amount} a été créé',
      updated: 'Un paiement d\'un montant de {amount} a été modifié',
      canceled: 'Un paiement d\'un montant de {amount} a été annulé',
      createdByUser: 'a créé un paiement d\'un montant de {amount}',
      updatedByUser: 'a modifié un paiement d\'un montant de {amount}',
      canceledByUser: 'a annulé un paiement d\'un montant de {amount}'
    },
    salesDocument: {
      male: {
        createdByUser: 'a créé le {type} n°',
        updatedByUser: 'a modifié le {type} n°',
        validatedByUser: 'a validé le {type} n°',
        sendedByUser: 'a envoyé le {type} n°',
        acceptedByUser: 'a marqué comme accepté le {type} n°',
        'advance-billedByUser': 'a facturé l\'acompte du {type} n°',
        'partially-billedByUser': 'a facturé partiellement le {type} n°',
        billedByUser: 'a facturé le {type} n°',
        refusedByUser: 'a marqué comme refusé le {type} n°',
        canceledByUser: 'a marqué comme annulé le {type} n°',

        created: 'Le {type} a été créé',
        updated: 'Le {type} a été modifié',
        validated: 'Le {type} a été validé',
        sended: 'Le {type} a été envoyé',
        accepted: 'Le {type} a été marqué comme accepté',
        'advance-billed': 'L\'acompte du {type} n°',
        'advance-billed2': 'a été facturé',
        'partially-billed': 'Le {type} a été facturé partiellement',
        billed: 'Le {type} a été facturé',
        refused: 'Le {type} a été marqué comme refusé',
        canceled: 'Le {type} a été marqué comme annulé',
      },
      female: {
        createdByUser: 'a créé la {type} n°',
        updatedByUser: 'a modifié la {type} n°',
        validatedByUser: 'a validé la {type} n°',
        sendedByUser: 'a envoyé la {type} n°',
        acceptedByUser: 'a marqué comme accepté la {type} n°',
        'advance-billedByUser': 'a facturé l\'acompte de la {type} n°',
        'partially-billedByUser': 'a facturé partiellement la {type} n°',
        billedByUser: 'a facturé la {type} n°',
        refusedByUser: 'a marqué comme refusé la {type} n°',
        canceledByUser: 'a marqué comme annulé la {type} n°',

        created: 'La {type} a été créée',
        updated: 'La {type} a été modifiée',
        validated: 'La {type} a été validée',
        sended: 'La {type} a été envoyée',
        accepted: 'La {type} a été marquée comme acceptée',
        'advance-billed': 'L\'acompte de la {type} n°',
        'advance-billed2': 'a été facturée',
        'partially-billed': 'La {type} a été facturée partiellement',
        billed: 'La {type} a été facturée',
        refused: 'La {type} a été marquée comme refusée',
        canceled: 'La {type} a été marquée comme annulée',
      },
      credit: {
        createdByUser: 'a créé l\'{type} n°',
        updatedByUser: 'a modifié l\'{type} n°',
        validatedByUser: 'a validé l\'{type} n°',
        sendedByUser: 'a envoyé l\'{type} n°',
        canceledByUser: 'a marqué comme annulé l\'{type} n°',

        created: 'L\'{type} a été créé',
        updated: 'L\'{type} a été modifié',
        validated: 'L\'{type} a été validé',
        sended: 'L\'{type} a été envoyé',
        canceled: 'L\'{type} a été marqué comme annulé',
      },
      actionCustomer: 'pour le client',
      actionAmount: 'd\'un montant de',
    },
    emails: {
      sended: {
        first: 'a envoyé',
        last: 'par email',
        lastAlone: 'un email',
        quotation: 'le devis n°',
        command: 'le bon de commande n°',
        proforma: 'la facture proforma n°',
        delivery: 'le bon de livraison n°',
        invoice: 'la facture n°',
        credit: 'l\'avoir n°'
      },
      error: 'Une erreur s\'est produie lors de l\'envoi de l\'email',
      read: 'L\'email a été lu par le destinataire',
    },
    comments: {
      first: 'a ajouté un commentaire sur',
      element_m: 'le {type}',
      element_f: 'la {type}',
      element_n: 'l\'{type}',
    },
    tasks: {
      created: 'a créé une tâche',
      updated: 'a modifié une tâche',
      end: 'a marqué comme terminé une tâche',
      todo: 'a marqué comme à faire une tâche',
    },
    opportunities: {
      created: 'a créé l\'opportunité n°',
      updated: 'a modifié l\'opportunité n°',
      win: 'a marqué comme gagnée l\'opportunité n°',
      losed: 'a marqué comme perdue l\'opportunité n°',
      moved: 'a déplacé l\'opportunité n°',
      customer: 'pour le client',
      actualStep: 'Étape actuelle : '
    },
    reports: {
      created: 'a créé un compte rendu',
      updated: 'a modifié un compte rendu',
      type1: 'd\'appel',
      type2: 'de rendez-vous'
    },
    letters: {
      created: 'a envoyé par lettre',
      updated: 'a modifié une lettre',
    },
  }
}

export default lang
