import Vue from 'vue'
import KwixeoGoogle from '@kwixeo/google-vue/src/install'
import store from '@/store'
import router from '@/router'
import axios from '@/axios'
import i18n from '@/lang/i18n'

Vue.use(KwixeoGoogle, {
  logo: require('@/assets/google.jpg'),
  axios,
  store,
  router,
  i18n
})
